import React from 'react';
import { PropTypes } from 'prop-types';
import ExecutiveFilter from '@fingo/lib/components/filters/ExecutiveFilter';
import HuntingFarmingFilter from '@fingo/lib/components/filters/HuntingFarmingFilter';
import CurrencyFilter from '@fingo/lib/components/filters/CurrencyFilter';
import BulkAssignExecutive from './BulkAssignExecutive';
import UploadContactabilityRequest from './UploadContactabilityRequest';

const CustomersActions = ({ setExecutiveAssigned, setFarmingHunting, currency, setCurrency }) => (
  <>
    <CurrencyFilter currency={currency} setCurrency={setCurrency} />
    <ExecutiveFilter setExecutiveCallback={setExecutiveAssigned} />
    <HuntingFarmingFilter setHuntingFarmingCallback={setFarmingHunting} />
    <BulkAssignExecutive />
    <UploadContactabilityRequest />
  </>
);

CustomersActions.propTypes = {
  setExecutiveAssigned: PropTypes.func.isRequired,
  setFarmingHunting: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
  setCurrency: PropTypes.func.isRequired,
};

export default CustomersActions;
